import React, { useContext } from "react";
import { Link } from "gatsby";
import { Row, Col, Container } from "react-bootstrap";
import PageWrapper from "../../components/PageWrapper";
import SEO from "../../components/Layout/SEO";
import SuccessStories from "../../sections/camelo/SuccessStories";

import bgSection from "../../assets/image/customers/jd_cafe_story_large.png";
import logo from "../../assets/image/customers/jdcafe.png";

const MiddleCTA = () => {
  return (
    <Row className="bg-default-1 border-top">
      <Container>
        <Col xs="12" className="text-center pb-24">
          <h2 className="gr-text-5 pt-24 pb-8">
            <span
              className="highlighted1"
              style={{ backgroundPosition: "0 20px" }}
            >
              Try Camelo now and see the difference yourself.
            </span>
          </h2>
          <div>
            <Link
              onClick={() => {
                if (typeof window.gtag_report_conversion === "function") {
                  window.gtag_report_conversion(
                    process.env.SIGNUP_URL,
                  );
                }
              }}
              to={process.env.SIGNUP_URL}
              target="__blank"
              className="btn btn-primary with-icon gr-hover-y"
            >
              Start scheduling free
              <i className="icon icon-tail-right font-weight-bold"></i>
            </Link>
            <p className="gr-text-12 pt-8">
              59 companies signed up in the last week alone!
            </p>
          </div>
        </Col>
      </Container>
    </Row>
  );
};

const NailStory = () => {
  return (
    <>
      <PageWrapper>
        <SEO title="Customer story: How This Coffee Shop Owner Turned From Complex Spreadsheets to One Simple App"></SEO>
        <div className="inner-banner bg-default-6 pt-24 pt-lg-30 pb-lg-15">
          <Container>
            <Row className="justify-content-center pt-5">
              <Col xl="8" lg="9">
                <div className="px-md-12 mb-13 text-center">
                  <h1 className="title gr-text-4 mb-8 mb-lg-12">
                    How This Coffee Shop Owner Turned From Complex Spreadsheets
                    to One Simple App
                  </h1>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div
          className="video-section bg-image py-28 py-lg-35 dark-mode-texts "
          css={`
            background-image: url(${bgSection});
          `}
        />

        <div className="pb-2 pb-lg-9 pt-13 pt-lg-24 bg-default-6 customer-story">
          <Container>
            <Row className="justify-content-center">
              <Col xl="10" lg="10">
                <div className="px-md-12 text-black mb-13 mb-lg-22">
                  <p className="gr-text-8 text-black">
                    J&D Café was opened based on the memories of a lost loved
                    one. The place hires 15 staff, and serves up to 100 guests
                    every day. Jared—the owner—manages his staff and business on
                    Google Sheets. When he found out Camelo, he finally said
                    goodbye to the silly mistakes due to back-and-forth jumping
                    between different spreadsheets and tracking things manually.
                  </p>
                  <h4 className="pt-8 pb-4">A typical white-collar life</h4>
                  <p className="gr-text-8 text-black">
                    Just like every university graduate, Jared quickly hunted
                    for a white-collar job in New York City. The salary and
                    benefits were attractive to Jared, but he quickly realized
                    his youthful passion was gone.
                  </p>
                  <p className="gr-text-8 text-black">
                    "I sat for 8 hours at the office every day in misery. Typed
                    stuff on the computer, reported to my manager, made some
                    social conversations with my colleagues, tried to survive
                    the day," says Jared.
                  </p>

                  <h4 className="pt-8 pb-4">Memories of a loved one</h4>
                  <p className="gr-text-8 text-black">
                    "My dad and I are coffee lovers. We tried different
                    varieties, from different countries. We often shared our
                    stories and life updates over breakfast, with 2 cups of
                    coffee," Jared shares.
                  </p>
                  <p className="gr-text-8 text-black">
                    Jared thought he would have to live that tedious corporate
                    life forever. One day, his dad got away in a car accident.
                    Jared felt empty. "Life was too short and unexpected to
                    settle for something I wasn't passionate about."
                  </p>

                  <h4 className="pt-8 pb-4">An idea born in the bathroom</h4>
                  <p className="gr-text-8 text-black">
                    "I don't know, the idea came to me when I was in the
                    shower," he laughs. "It was weird, but it was like a spark
                    in my mind. I missed our coffee mornings and conversations,
                    so I wanted to recreate those moments for other people."
                  </p>
                  <p className="gr-text-8 text-black">
                    The man knew he had to be prepared for the whole exciting,
                    yet uncertain journey ahead. "I dug every corner of the
                    Internet to find out how to open and run a small business,
                    how to hire and communicate with staff, how to serve
                    customers, challenges, stories, and the list of questions
                    are endless. I'm still trying to answer every one of them
                    now," Jared says.
                  </p>
                  <p className="gr-text-8 text-black">
                    He did research, made plans and calculations every free
                    moment he had. He made trips to different coffee farms every
                    weekend to find the best coffee beans that suit his budget
                    and meet his standards.
                  </p>

                  <h4 className="pt-8 pb-4">
                    From the bathroom to the reality
                  </h4>
                  <p className="gr-text-8 text-black">
                    Jared finally quit his suit-and-tie job after lots of
                    hesitation and even procrastination. He says, "I was too
                    scared things wouldn't turn out well, but I finally opened
                    J&D Café".
                  </p>
                  <p className="gr-text-8 text-black">
                    At first, it was just Jared and another staff. He woke up at
                    3:00 a.m. to roast coffee, clean the shop, and arrange the
                    tables. By 8:00 a.m., his staff came in to help with the
                    brewing and serving. There weren't too many guests, so even
                    though things got hectic, they could manage to serve their
                    customers well.
                  </p>
                  <p className="gr-text-8 text-black">
                    "My staff left at 5:00 p.m. And there was just me until the
                    coffee shop closed at 9:00 p.m. I then had to do some
                    cleaning and accounting until 11:00 p.m. Months of sleep
                    deprivation and exhaustion, but Jared could feel the passion
                    and the willingness that he couldn't feel in his previous
                    job.
                  </p>
                  <p className="gr-text-8 text-black">
                    The place started to attract more guests. The man had enough
                    budget to hire more staff, but never really had extra time.
                    Jared says, "I still slept 4-5 hours every night because I
                    spent all of my free time managing my staff and budget,
                    looking for ways to upgrade the service quality.
                  </p>

                  <h4 className="pt-8 pb-4">
                    Finding a new and better way to run business
                  </h4>
                  <p className="gr-text-8 text-black">
                    Jared stored his staff's information, shifts, availability,
                    and skills on Google Sheets. One sheet for staff information
                    such as name, age, qualifications, skills, preferred work
                    hours, availability, and contact info. One sheet for the
                    weekly schedule, which he shared accessibility with all of
                    his workers. One sheet for time-off requests. And one for
                    on-call staff, which he can call in urgent situations.
                  </p>
                  <p className="gr-text-8 text-black">
                    "I jumped back and forth between different spreadsheets to
                    make weekly schedules. For example, I had to find someone
                    with barista skills to replace the absent barista, and
                    someone who's available in the morning to cover a morning
                    shift," he says. He wasn't always accurate. Sometimes he
                    forgot to find a replacement, or put a waiter in to cover
                    for the barista. He had to step in and cover the shifts by
                    himself if he made similar mistakes.
                  </p>
                  <blockquote className="blockquote card-body">
                    <p className="text-black pt-4 gr-text-6 lead">
                      "I knew employee scheduling software was a thing, so I
                      tried a few options out to see what fitted. Camelo was my
                      final decision. It allows me to assign shifts quickly on
                      my smartphone. I can keep track of the staff's
                      availability and skills. Time-off requests can be sent and
                      approved on the app, too."
                    </p>
                    <footer className="blockquote-footer">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="gr-text-10 gr-color-blackish-blue-opacity-8">
                          <div>
                            Jared Lebsack
                            <span> (Owner)</span>
                          </div>
                        </div>
                      </div>
                    </footer>
                  </blockquote>
                  <p className="gr-text-8 text-black">
                    Jared loves that there's a list of suggested staff who can
                    cover for the off-employees so that he can select right
                    away. And if someone has questions or wants changes, they
                    can text him right on the app and they can figure things out
                    quickly together.
                  </p>
                  <p className="gr-text-8 text-black">
                    Jared's staff are fond of Camelo because they can ask for
                    time off without many emails back and forth or complicated
                    documents. There are shift cards that they can view when
                    opening the app, plus reminders, so they never miss their
                    shifts.
                  </p>
                  <h4 className="pt-8 pb-4">
                    More time for other important matters
                  </h4>
                  <p className="gr-text-8 text-black">
                    Jared's planning to expand his coffee shop to another
                    location near his dad's house. It's his way of saying "Hey
                    Dad, this is for you!". Running 2 coffee shops at the same
                    time isn't challenging anymore because Camelo lets Jared
                    manage multiple locations on the app, plus he's planning to
                    hire a new manager to help.
                  </p>
                  <p className="gr-text-8 text-black">
                    "I still had thoughts like "Hell, why did I choose this
                    path?"," Jared admits. "But the doubts quickly disappear. I
                    just keep moving."
                  </p>
                  <blockquote className="blockquote card-body">
                    <p className="text-black pt-4 gr-text-6 lead">
                      "I have more time for other important matters. Everything
                      is on the right path, and I hope all goes well."
                    </p>
                    <footer className="blockquote-footer">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="gr-text-10 gr-color-blackish-blue-opacity-8">
                          <div>
                            Jared Lebsack
                            <span> (Owner)</span>
                          </div>
                        </div>
                      </div>
                    </footer>
                  </blockquote>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <MiddleCTA />
        <SuccessStories />
      </PageWrapper>
    </>
  );
};
export default NailStory;
